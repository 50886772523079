<template>
  <div class="p-col-12 info p-mt-4">
    <div class="p-col-6" v-if="item">
      <h4>{{ i18n.$t('Info') }}</h4>
      <table class="vertical-talbe">
        <tr v-if="item.category">
          <th>{{ i18n.$t('Category') }}</th>
          <td>
            <span>{{ item.category.name }}</span>
          </td>
        </tr>
        <tr v-if="item.name">
          <th>{{ i18n.$t('Name') }}</th>
          <td>{{ item.name }}</td>
        </tr>
        <tr v-if="item.type">
          <th>{{ i18n.$t('Type') }}</th>
          <td>{{ item.type }}</td>
        </tr>
        <tr v-if="item.description">
          <th>{{ i18n.$t('Description') }}</th>
          <td v-html="item.description" />
        </tr>
        <tr v-if="item.employee">
          <th>{{ i18n.$t('List of employee') }}</th>
          <td>
            <span v-for="el in item.employee" :key="el.first_name"
              >{{ el.first_name ? el.first_name : '' }}
              {{ el.last_name ? el.last_name : '' }},</span
            >
          </td>
        </tr><!-- 
        <tr v-if="item.group_id">
          <th>{{ i18n.$t('List of group') }}</th>
          <td>
            <span v-for="el in item.group_id" :key="el.name"
              >{{ el.name }},</span
            >
          </td>
        </tr> -->
        <tr v-if="item.book_duration">
          <th>{{ i18n.$t('Book duration') }}</th>
          <td>{{ item.book_duration }} minutes</td>
        </tr>
        <tr v-if="item.count_booking_per_user">
          <th>{{ i18n.$t('Number of reservation units') }}</th>
          <td>{{ item.count_booking_per_user }}</td>
        </tr>
        <tr v-if="item.color">
          <th>Color :</th>
          <td><ColorPicker style="max-width: 28px" v-model="item.color" /></td>
        </tr>
        <tr>
          <th>{{ i18n.$t('Full info contact') }}</th>
          <td>{{ item.is_fill_contact ? 'Yes' : 'No' }}</td>
        </tr>
        <tr v-if="item.price">
          <th>{{ i18n.$t('Price') }}</th>
          <td>{{ item.price }} {{ item.currency ? item.currency : '€' }}</td>
        </tr>
        <tr v-if="item.motifs && item.motifs.length">
          <th>{{ i18n.$t('motifs') }}</th>
          <td>
            <span v-for="el in item.motifs" :key="el.id">{{ el.value }},</span>
          </td>
        </tr>
        <tr v-if="agendasService && agendasService.length">
          <th>{{ i18n.$t('Agenda') }}</th>
          <td>
            <span v-for="el in agendasService" :key="el._id">{{ el.name }},</span>
          </td>
        </tr>

        <tr></tr>
      </table>
    </div>
    <div class="p-col-6 p-mt-4" v-if="employeeForTable">
      <h4>{{ i18n.$t('service employee list') }}</h4>
      <DataTable class="table-2-columns" :value="employeeForTable">
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  FETCH_AGENDA,
  GET_AGENDA,
  FETCH_CATEGORY_SERVICE,
  FETCH_GROUPS,
  GET_GROUPS,
  GET_USERDATA
} from '@/store/types';
export default {
  props: {
    item: {
      type: Object
    },
    employees: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);
    const agendas = computed(() => store.getters[GET_AGENDA]);
    const groups = computed(() => store.getters[GET_GROUPS]);

    store.dispatch(FETCH_GROUPS, userData.value.id);
    store.dispatch(FETCH_AGENDA, userData.value.id);
    store.dispatch(FETCH_CATEGORY_SERVICE, userData.value.organisation_id);

    const agendasService = computed(() => {
      if (props.item.agenda_id) {
        return agendas.value.filter((el) =>
          props.item.agenda_id.includes(el._id)
        );
      } else return null;
    });

    const groupsService = computed(() => {
      if (props.item.group_id) {
        return groups.value.filter((el) =>
          props.item.group_id.includes(el._id)
        );
      } else return false;
    });

    return {
      i18n,
      agendas,
      groups,
      groupsService,
      agendasService
    };
  }
};
</script>

<style lang="scss" scoped>
h4 {
  line-height: 100%;
}
.p-col-6 {
  padding: 0;
}
.vertical-talbe {
  border-collapse: collapse;
  td,
  th {
    text-align: left;
    padding: 1rem 0 1rem 0;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
  }
  th {
    width: 35%;
    vertical-align: top;
  }
  td {
    width: 65%;
    padding: 1rem 0 1rem 1rem;
  }
}
td {
  span {
    display: block;
  }
}
</style>